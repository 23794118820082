import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Menu, MenuHandler } from '@material-tailwind/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

export default function JournalDrop() {
	const lang = localStorage.getItem('language');
	const [openMenu, setOpenMenu] = React.useState(false);
	const { pathname } = useLocation();
	const isCurrentPath = (path) => {
		return pathname === path;
	};

	const triggers = {
		onMouseEnter: () => setOpenMenu(true),
		onMouseLeave: () => setOpenMenu(false)
	};

	return (
		<Menu open={openMenu} handler={setOpenMenu}>
			<MenuHandler>
				<a
					href="https://nilmrc-journal.vercel.app/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<p
						{...triggers}
						variant="paragraph"
						className={`hover:bg-red-900 flex gap-2 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-[6px] lg:border-l-0  ${
							isCurrentPath('/journal') ? 'bg-red-900 text-white ' : ''
						}`}
					>
						{lang === 'en' ? 'Journal Pubications' : 'জার্নাল পাবলিকেশন্স'}
						<span className="block lg:hidden">
							<ChevronDownIcon
								strokeWidth={1.5}
								className={`h-3.5 w-3.5 transition-transform ${
									openMenu ? 'rotate-180' : ''
								}`}
							/>
						</span>
					</p>
				</a>
			</MenuHandler>
		</Menu>
	);
}
